<template>
  <b-card

    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Répartition par risque</b-card-title>

      <!-- <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          28 derniers jours
        </b-dropdown-item>
        <b-dropdown-item href="#">
          le mois dernier
        </b-dropdown-item>
        <b-dropdown-item href="#">
          l'année dernière
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div
        class="transaction-item pb-1"
        v-for="(st,index) in statistiqueProjetsRisque" :key="index"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="transaction-title text-uppercase">
              {{st[0]}}
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-primary"
          v-if="renderComponent"
        >
         {{getRisquePercent(st[1])}} %
        </div>
      </div>

      <!-- <div

        class="transaction-item pb-1"
      >
        <b-media no-body>
          <b-media-aside>
            <svg style="color: #4d25bc;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M8 0a.5.5 0 0 1 .5.5v.514C12.625 1.238 16 4.22 16 8c0 0 0 .5-.5.5c-.149 0-.352-.145-.352-.145l-.004-.004l-.025-.023a3.484 3.484 0 0 0-.555-.394A3.166 3.166 0 0 0 13 7.5c-.638 0-1.178.213-1.564.434a3.484 3.484 0 0 0-.555.394l-.025.023l-.003.003s-.204.146-.353.146s-.352-.145-.352-.145l-.004-.004l-.025-.023a3.484 3.484 0 0 0-.555-.394a3.3 3.3 0 0 0-1.064-.39V13.5H8h.5v.039l-.005.083a2.958 2.958 0 0 1-.298 1.102a2.257 2.257 0 0 1-.763.88C7.06 15.851 6.587 16 6 16s-1.061-.148-1.434-.396a2.255 2.255 0 0 1-.763-.88a2.958 2.958 0 0 1-.302-1.185v-.025l-.001-.009v-.003s0-.002.5-.002h-.5V13a.5.5 0 0 1 1 0v.506l.003.044a1.958 1.958 0 0 0 .195.726c.095.191.23.367.423.495c.19.127.466.229.879.229s.689-.102.879-.229c.193-.128.328-.304.424-.495a1.958 1.958 0 0 0 .197-.77V7.544a3.3 3.3 0 0 0-1.064.39a3.482 3.482 0 0 0-.58.417l-.004.004S5.65 8.5 5.5 8.5c-.149 0-.352-.145-.352-.145l-.004-.004a3.482 3.482 0 0 0-.58-.417A3.166 3.166 0 0 0 3 7.5c-.638 0-1.177.213-1.564.434a3.482 3.482 0 0 0-.58.417l-.004.004S.65 8.5.5 8.5C0 8.5 0 8 0 8c0-3.78 3.375-6.762 7.5-6.986V.5A.5.5 0 0 1 8 0zM6.577 2.123c-2.833.5-4.99 2.458-5.474 4.854A4.124 4.124 0 0 1 3 6.5c.806 0 1.48.25 1.962.511a9.706 9.706 0 0 1 .344-2.358c.242-.868.64-1.765 1.271-2.53zm-.615 4.93A4.16 4.16 0 0 1 8 6.5a4.16 4.16 0 0 1 2.038.553a8.688 8.688 0 0 0-.307-2.13C9.434 3.858 8.898 2.83 8 2.117c-.898.712-1.434 1.74-1.731 2.804a8.687 8.687 0 0 0-.307 2.131zm3.46-4.93c.631.765 1.03 1.662 1.272 2.53c.233.833.328 1.66.344 2.358A4.14 4.14 0 0 1 13 6.5c.77 0 1.42.23 1.897.477c-.484-2.396-2.641-4.355-5.474-4.854z"/></svg>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title text-uppercase">
              Prévoyance tns
            </h6>

          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-primary"
        >
          12.5%
        </div>
      </div> -->

      <!-- <div
        class="transaction-item pb-1"
      >
        <b-media no-body>
          <b-media-aside>
            <svg style="color: #4d25bc;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">

                             <path fill="currentColor" d="M3 18v-5a2 2 0 0 1 2-2V8a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4v3a2 2 0 0 1 2 2v5a2 2 0 1 1-4 0H7a2 2 0 1 1-4 0ZM9 6a2 2 0 0 0-2 2v3h10V8a2 2 0 0 0-2-2H9Zm-3 9a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm12 0a1 1 0 1 0 0-2a1 1 0 0 0 0 2Z"/>

                            </svg>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title text-uppercase">
              Auto
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-primary"
        >
          19.9%
        </div>
      </div> -->

      <!-- <div

        class="transaction-item pb-1"
      >
        <b-media no-body>
          <b-media-aside>
            <svg style="color: #4d25bc;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m4.293 10.707l7-7a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 .293.707V21a1 1 0 0 1-1 1h-5v-7h-4v7H5a1 1 0 0 1-1-1v-9.586a1 1 0 0 1 .293-.707Z"/></svg>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title text-uppercase">
              MRH
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-primary"
        >
          15.5%
        </div>
      </div> -->
      <!-- <div

        class="transaction-item pb-1"
      >
        <b-media no-body>
          <b-media-aside>
            <svg style="color: #4d25bc;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4zm0 9.6a2.2 2.2 0 1 0 0 4.402a2.2 2.2 0 0 0 0-4.402z"/></svg>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title text-uppercase">
              Autres
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-primary"
        >
          24%
        </div>
      </div> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    statistiqueProjetsRisque: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    statistiqueProjetsRisque: function(old, nld) {
      this.filterProjetsData(old)
      this.test=old
    }
  },
  data(){
    return {
      test:[],
      totalRisque:0,
      renderComponent:false
    }
  },
  methods:{
    filterProjetsData(data){
      data.map((dt)=>{
        this.totalRisque+=dt[1]
      })
      this.renderComponent=true
    },
    getRisquePercent(value) {
      return ((value/this.totalRisque)*100).toFixed(1)
    }
  }
}
</script>
